import {Box, IconButton, TableCell, TableRow, Typography} from '@mui/material';
import {observer} from 'mobx-react';
import {IExpanded} from '../../core';
import {Cell} from '.';
import {ChevronDown as ChevronDownIcon} from '../../icons/chevron-down';
import {ChevronRight as ChevronRightIcon} from '../../icons/chevron-right';
import {ChevronLeft as ChevronLeftIcon} from '../../icons/chevron-left';
import {useTranslation} from "react-i18next";

export const EmptyRow = () => {
    const {t} = useTranslation();

    return (
        <TableRow>
            <Cell
                style={{
                    width: 100,
                    height: 30,
                    textAlign: 'center',
                    padding: 4,
                    borderBottom: 'unset',

                }}
                span={100}
            >
                {t('dcrPage.empty')}
            </Cell>
        </TableRow>
    );
};

export const EmptyBox = () => {
    const {t} = useTranslation();

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', p: 2}}>
            <Typography>
                {t('dcrPage.empty')}
            </Typography>
        </Box>
    );
};

interface ExpandRowProps<T extends IExpanded> {
    item: T;
    children?: React.ReactNode,
    style?: React.CSSProperties,
    colSpan?: number
}

export const ExpandRow = observer(<T extends IExpanded>({children, item, style, colSpan = 10, ...other}: ExpandRowProps<T>) => {
    return (
        <TableRow>
            <Cell
                colSpan={colSpan}
                sx={{
                    p: 0,
                    position: 'relative',
                    '&:after': {
                        position: 'absolute',
                        content: '" "',
                        top: 0,
                        left: 0,
                        backgroundColor: 'primary.main',
                        width: 3,
                        height: 'calc(100% + 1px)'
                    }
                }}
            >
                {children}
            </Cell>
        </TableRow>

    );
});

interface ExpandButtonProps<T extends IExpanded> {
    item: T,
    style?: React.CSSProperties,
    isOpen?: boolean,
    onClick?: () => void
    isDcr?: boolean
    testid?: string
}

export const ExpandButton = observer(<T extends IExpanded>({
                                                               item,
                                                               style,
                                                               isOpen,
                                                               onClick,
                                                               isDcr,
                                                               testid
                                                           }: ExpandButtonProps<T>) => {
    let icons = isOpen ? <ChevronDownIcon fontSize="small"/> : <ChevronRightIcon fontSize="small"/>
    if (isDcr) {
        icons = isOpen ? <ChevronLeftIcon fontSize="small"/> : <ChevronRightIcon fontSize="small"/>
    }
    return (
        <TableCell
            data-testid={`${testid}-expand-tableBody`}
            padding="none"
            sx={{
                ...(isOpen && {
                    position: 'relative',
                    '&:after': {
                        position: 'absolute',
                        content: '" "',
                        top: 0,
                        left: 0,
                        backgroundColor: 'primary.main',
                        width: 3,
                        height: 'calc(100% + 1px)'
                    }
                })
            }}
            width='3%'
        >
            <IconButton
                data-testid={`${testid}-expand-btn`}
                aria-label="expand row"
                size="small"
                style={style}
                onClick={() => {
                    item.expand = !item.expand
                    onClick && onClick();
                }}
            >
                {icons}
            </IconButton>
        </TableCell>

    );
});

