import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {NotesTable} from "./NotesTable";
import {NotesTabsStore} from "../../../../stores/management/organizations/details/notes/NotesTabsStore";
import React from "react";
import {Page, PageTab, PageTabs} from "../../../../components";

interface DetailsProps {
    store: NotesTabsStore;
    style?: React.CSSProperties;
}

export const NotesTabs = observer(({store, style}: DetailsProps) => {
    const {t} = useTranslation();

    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return <NotesTable store={store.userNotes}/>;
            case 1:
                return <NotesTable store={store.adminNotes}/>;
            default:
                return null;
        }
    };

    return (
        <Page
            style={style}
        >
            <PageTabs
                style={{marginTop: -4, marginBottom: 8}}
                index={store.tabIndex}
                onChange={async (t) => store.tabIndex = t}>
                <PageTab label="Пользовательские" testid="JobsTabs-double-tabs"/>
                <PageTab label="Системные" testid="JobsTabs-available-tabs"/>
            </PageTabs>
            <div style={{marginTop: 4, marginBottom: 4}}>
                {renderTabs(store.tabIndex)}
            </div>
        </Page>
    );
});
