import {Box, Card, Table, TableBody, TableHead, TableRow} from "@mui/material";
import {observer} from "mobx-react";
import {Fragment} from 'react';
import {useTranslation} from "react-i18next";
import {
    AuthorizePermission,
    Cell,
    ConfirmationForm,
    EmptyBox,
    ExpertDialog,
    HCell,
    Page,
    Pagination,
    Progress,
    SimpleButton
} from '../../../../components';
import {MenuComponent} from "../../../../components/MenuComponent";
import {MenuItemComponent} from "../../../../components/MenuItemComponent";
import {NavLink} from "../../../../components/NavLink";
import {getObject, toDateTimeString} from "../../../../core";
import {AllPermissions, OrganizationStatuses} from "../../../../core/const";
import {DeleteIcon} from "../../../../icons/deleteIcon";
import {Scrollbar} from "../../../../scrollbar";
import {AuthorizationState} from "../../../../stores/authorization/AuthorizationStore";
import {NotesTableStore} from "../../../../stores/management/organizations/details/notes/NotesTableStore";
import {NotesCreate} from "./NotesCreate";

interface Props {
    store: NotesTableStore;
    style?: React.CSSProperties;
}

export const NotesTable = observer(({store, style}: Props) => {
    const currentUser = getObject<AuthorizationState>('authorization-store')?.token;
    const {t} = useTranslation();

    return (
        <>
            {
                store.current
                    ? (
                        <ExpertDialog
                            title={t('common.delete')}
                            open={store.deleteDialogState}
                            onClose={() => store.deleteDialogState = false}
                        >
                            <ConfirmationForm
                                disabled={store.processing}
                                onSubmit={async () => {
                                    await store?.remove()
                                }}
                                onClose={() => store.deleteDialogState = false}
                            >
                                {t('common.deleteConfirm')} "{store.current.note}"?
                            </ConfirmationForm>
                        </ExpertDialog>
                    )
                    : ''
            }
            <Page style={style}>
                <ExpertDialog
                    title={t('create.notes')}
                    open={store.creator.Open}
                    onClose={() => {
                        store.creator.Open = false;
                        store.creator.reset()
                    }}
                >
                    <NotesCreate store={store.creator}/>
                </ExpertDialog>

                <Box sx={{mt: 1, ml: 'auto'}} style={{height: 50}}>
                    {
                        !store.isAdmin &&
                        <AuthorizePermission permissions={AllPermissions.person.update}>
                            <SimpleButton title={t('create.notes')} onClick={() => store.creator.Open = true}
                                          style={{width: 200}}
                                          disabled={store._store._store.id === OrganizationStatuses.Double}/>
                        </AuthorizePermission>
                    }
                </Box>

                <Card sx={{mb: 3, overflow: 'unset'}}>
                    <Scrollbar>
                        <Table sx={{minWidth: 850}}>
                            <TableHead>
                                <TableRow>
                                    <HCell orderBy='user' store={store} style={{padding: '6px 0 6px 16px',}} width='20%'
                                           testid="notes-uName">{t('table.uName')}</HCell>
                                    <HCell orderBy='notes' store={store} width='50%'
                                           testid="notes-notes">{t('table.notes')}</HCell>
                                    <HCell orderBy='createdDate' store={store} width='15%'
                                           testid="notes-create">{t('table.createdDate')}</HCell>
                                    <HCell store={store} width='5%' style={{padding: '0 15px'}}
                                           testid="notes-action">{t('table.action')}</HCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    store.items.length > 0
                                        ? store.items.map(item => {
                                            const open = false;
                                            return (
                                                <Fragment key={item.id}>
                                                    <TableRow
                                                        hover
                                                        key={item.id}
                                                        onClick={() => store.current = item}
                                                    >
                                                        <Cell testid="notes-userName">
                                                            <Box sx={{pl: 2, py: 2}}>
                                                                <NavLink to={`/users/${item.id}`} target="_blank">
                                                                    {item.createdByUser?.username}
                                                                </NavLink>
                                                            </Box>
                                                        </Cell>
                                                        <Cell testid="notes-notes" sx={{
                                                            maxWidth: 500,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            {item.note}
                                                        </Cell>
                                                        <Cell testid="notes-create">
                                                            {toDateTimeString(item.createdAt)}
                                                        </Cell>
                                                        <Cell align={'center'} testid="notes-action">
                                                            {(item.createdByUser?.id === currentUser?.sub || currentUser?.roles?.includes('Admin')) &&
                                                                <AuthorizePermission
                                                                    permissions={AllPermissions.organization.delete}>
                                                                    <MenuComponent>
                                                                        <MenuItemComponent text={t('common.delete')}
                                                                                           icon={<DeleteIcon
                                                                                               fontSize="small"/>}
                                                                                           onClick={() => {
                                                                                               store.deleteDialogState = true
                                                                                           }}
                                                                                           disabled={store._store._store.id === OrganizationStatuses.Double}
                                                                                           style={{color: '#D14343'}}/>
                                                                    </MenuComponent>
                                                                </AuthorizePermission>
                                                            }
                                                        </Cell>
                                                    </TableRow>
                                                </Fragment>)
                                        })
                                        : <Cell colSpan={4}>
                                            <EmptyBox/>
                                        </Cell>
                                }
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </Card>
                {store.items.length > 0 && <Pagination store={store}/>}

                {!store.items.length && <Box
                    sx={{
                        flexGrow: 1,
                        py: 3,
                    }}
                >
                    <Progress store={store}/>
                </Box>
                }
            </Page>
        </>
    );
});
