import type {FC, ReactNode} from 'react';
import {useState} from "react";
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import type {Section} from '../config';
import {SideNav} from './side-nav';
import {TopNav} from './top-nav';
import {NavColor} from "../../../contexts/settings/types";
import {useSettings} from "../../../core/hooks/use-settings";
import {Theme, useMediaQuery} from "@mui/material";
import {useMobileNav} from "../horizontal-layout/use-mobile-nav";
import {MobileNav} from "../mobile-nav";

const SIDE_NAV_WIDTH = 260;
const SMALL_SIDE_NAV_WIDTH = 81;

const VerticalLayoutRoot = styled('div',{shouldForwardProp: (prop) => prop !== 'width'})<{ width: number; }>(
    ({theme, width}) => ({
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: '100%',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: width
        },
        transition: '0.4s',
    })
);

const VerticalLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
});

interface VerticalLayoutProps {
    children?: ReactNode;
    navColor?: NavColor;
    sections?: Section[];
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
    const {children, sections, navColor} = props;
    const [openSideBar, setOpenSideBar] = useState<boolean>(true);
    const settings = useSettings();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const mobileNav = useMobileNav();

    const handleSetOpenSideBar = (value: boolean) => {
        setOpenSideBar(value)
        settings.handleUpdate({openSideBar: value})
    }

    return (
        <>
            <TopNav onMobileNavOpen={mobileNav.handleOpen} sideNavWidth={openSideBar ? SIDE_NAV_WIDTH : SMALL_SIDE_NAV_WIDTH}/>
            {lgUp && (
                <SideNav
                    color={navColor}
                    sections={sections}
                    openSideBar={openSideBar}
                    setOpenSideBar={handleSetOpenSideBar}
                />
            )}
            {!lgUp && (
                <MobileNav
                    color={navColor}
                    onClose={mobileNav.handleClose}
                    open={mobileNav.open}
                    sections={sections}
                />
            )}
            <VerticalLayoutRoot width={openSideBar ? SIDE_NAV_WIDTH : SMALL_SIDE_NAV_WIDTH}>
                <VerticalLayoutContainer>
                    {children}
                </VerticalLayoutContainer>
            </VerticalLayoutRoot>
        </>
    );
};

VerticalLayout.propTypes = {
    children: PropTypes.node,
    navColor: PropTypes.oneOf<NavColor>(['blend-in', 'discrete', 'evident']),
    sections: PropTypes.array
};
