import {
    Box,
    Card,
    FormControlLabel,
    Switch,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import {observer} from 'mobx-react';
import {Fragment, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {
    AddButton,
    AddLocalityButton,
    AuthorizePermission,
    Cell,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Pagination,
    Progress,
    ResetFiltersButton,
    Search,
} from '../../components';
import {CopyToClipboard} from '../../components/CopyToClipboard';
import {MenuComponent} from '../../components/MenuComponent';
import {MenuItemComponent} from '../../components/MenuItemComponent';
import {NavLink} from '../../components/NavLink';
import {TabTitle} from '../../components/TabTitle';
import {FiltersComponent} from '../../components/filters/FiltersComponent';
import {LocalityFilter} from '../../components/select/Locality/LocalityFilter';
import {SeverityPill, SeverityPillColor,} from '../../components/severity-pill';
import {DefaultMultiSelect} from '../../components/shared/components/DefaultMultiSelect';
import {AllPermissions} from '../../core/const';
import {CloudUpload as CloudUploadIcon} from '../../icons/cloud-upload';
import {useManagementStore} from '../../stores';
import {OrganizationDetails} from './OrganizationDetails';
import {OrganizationCreator} from './creator/OrganizationCreator';

export const organizationStatusHandler = (status: string) => {
    if (status === 'Не работает') {
        return 'warning';
    } else if (
        status === 'Требует дополнительной валидации' ||
        status === 'Не удалось уточнить'
    ) {
        return 'info';
    } else if (status === 'МДМ-репчеки') {
        return 'secondary';
    } else if (
        status === 'Временно не работает' ||
        status === 'Расформировано' ||
        status === 'Отклонено' ||
        status === 'Дубль'
    ) {
        return 'error';
    } else {
        return 'success';
    }
};

export const OrganizationTable = observer(() => {
    const { organizationTableStore } = useManagementStore();
    const { t } = useTranslation();
    const tableTopRef = useRef<null | HTMLDivElement>(null);

    const scrollToTop = () => {
        tableTopRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [organizationTableStore.value]);

    return (
        <AuthorizePermission
            permissions={AllPermissions.organization.read}
            showWarning
        >
            <>
                <ExpertDialog
                    title={t('create.organization')}
                    open={organizationTableStore.creator.Open}
                    withApproveClose={organizationTableStore.creator.isDirty}
                    onClose={() => {
                        organizationTableStore.creator.Open = false;
                        organizationTableStore.creator.reset();
                    }}
                >
                    <OrganizationCreator
                        store={organizationTableStore.creator}
                    />
                </ExpertDialog>
                <TabTitle title={t('pageNames.organizations')}>
                    <AuthorizePermission
                        permissions={AllPermissions.organization.create}
                    >
                        <AddButton
                            onClick={() =>
                                (organizationTableStore.creator.Open = true)
                            }
                            testid="organizationTable-create-btn"
                        />
                    </AuthorizePermission>
                </TabTitle>

                <FiltersComponent>
                    <Box
                        sx={{ mt: 1 }}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Search
                            autoFocus
                            value={organizationTableStore.search.value}
                            onChange={(t) =>
                                (organizationTableStore.search.value = t)
                            }
                            style={{ width: '24%' }}
                            testid="organizationTable-organization"
                        />
                        <Search
                            value={organizationTableStore.searchById.value}
                            onChange={(t) =>
                                (organizationTableStore.searchById.value = t)
                            }
                            placeholder="Id"
                            style={{ width: '24%' }}
                            testid="organizationTable-id"
                        />
                        <Search
                            value={
                                organizationTableStore.searchByExternalId.value
                            }
                            onChange={(t) =>
                                (organizationTableStore.searchByExternalId.value =
                                    t)
                            }
                            placeholder="External Id"
                            style={{ width: '24%' }}
                            testid="organizationTable-externalId"
                        />
                        <Search
                            value={
                                organizationTableStore.searchByAntidoubleId
                                    .value
                            }
                            onChange={(t) =>
                                (organizationTableStore.searchByAntidoubleId.value =
                                    t)
                            }
                            placeholder="Antidouble Id"
                            style={{ width: '24%' }}
                            testid="organizationTable-antidoubleId"
                        />
                    </Box>
                    <Box
                        sx={{ mt: 0 }}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <DefaultMultiSelect
                            store={organizationTableStore.formPropertyFilter}
                            title={t('common.formProperty')}
                            style={{ width: '24%' }}
                            testid="organizationTable-formProperty"
                        />
                        <DefaultMultiSelect
                            store={organizationTableStore.statusFilter}
                            title={t('common.status')}
                            style={{ width: '24%' }}
                            nameExp={(dto) => dto.nameRus || 'Работает'}
                            testid="organizationTable-status"
                        />
                        <DefaultMultiSelect
                            store={organizationTableStore.typeFilter}
                            title={t('common.type')}
                            style={{ width: '24%' }}
                            testid="organizationTable-type"
                        />
                        <Search
                            placeholder={t('common.itn').toUpperCase()}
                            value={organizationTableStore.searchITN}
                            onChange={(t) =>
                                (organizationTableStore.searchITN = t)
                            }
                            style={{ width: '24%', marginTop: 16 }}
                            testid="organizationTable-searchITN"
                        />
                    </Box>

                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: 'auto',
                        }}
                        sx={{ my: 2 }}
                    >
                        <ResetFiltersButton
                            onClick={() =>
                                organizationTableStore.resetFilters()
                            }
                            testid="organizationTable-resetFilter-btn"
                        />
                        <Box style={{ display: 'flex' }}>
                            <FormControlLabel
                                labelPlacement="end"
                                label={t('common.Parent')}
                                control={
                                    <Switch
                                        checked={
                                            organizationTableStore.hasNoParent
                                                .value!
                                        }
                                        onClick={() =>
                                            (organizationTableStore.hasNoParent.value =
                                                !organizationTableStore
                                                    .hasNoParent.value)
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                labelPlacement="end"
                                label={t('common.emptyITN')}
                                control={
                                    <Switch
                                        checked={
                                            organizationTableStore.emptyITN
                                                .value!
                                        }
                                        onClick={() =>
                                            (organizationTableStore.emptyITN.value =
                                                !organizationTableStore.emptyITN
                                                    .value)
                                        }
                                    />
                                }
                            />
                            <LocalityFilter
                                store={organizationTableStore.localitiesFilter}
                                style={{ marginLeft: 8 }}
                            />
                            <AddLocalityButton
                                onClick={() =>
                                    organizationTableStore.localitiesFilter.open()
                                }
                                testid="organizationTable-addLocality-btn"
                            />
                        </Box>
                    </Box>
                </FiltersComponent>

                <Card sx={{ mb: 8, overflow: 'unset' }}>
                    <Table sx={{ minWidth: 1250 }}>
                        <TableHead
                            style={{ position: 'sticky', top: 63, zIndex: 999 }}
                        >
                            <TableRow>
                                <HCell
                                    width="3%"
                                    testid="organizationTable-expand"
                                ></HCell>
                                <HCell
                                    orderBy="id"
                                    store={organizationTableStore}
                                    style={{width: 60}}
                                    testid="organizationTable-id"
                                >
                                    Id
                                </HCell>
                                <HCell
                                    orderBy="externalId"
                                    style={{width: 60}}
                                    testid="organizationTable-externalId"
                                    store={organizationTableStore}
                                >
                                    ExternalId
                                </HCell>
                                <HCell
                                    orderBy="orgName"
                                    testid="organizationTable-orgName"
                                    store={organizationTableStore}
                                    style={{ width: 180 }}
                                >
                                    {t('common.name')}
                                </HCell>
                                <HCell
                                    orderBy="orgStatus"
                                    store={organizationTableStore}
                                    testid="organizationTable-orgStatus"
                                    style={{ width: 120 }}
                                >
                                    {t('table.status')}
                                </HCell>
                                <HCell
                                    orderBy="typeOrg"
                                    testid="organizationTable-typeOrg"
                                    store={organizationTableStore}
                                    style={{ width: 150 }}
                                >
                                    {t('table.type')}
                                </HCell>
                                <HCell
                                    orderBy="address"
                                    testid="organizationTable-address"
                                    store={organizationTableStore}
                                    style={{ width: 180 }}
                                >
                                    {t('table.address')}
                                </HCell>
                                <HCell
                                    orderBy="itn"
                                    testid="organizationTable-itn"
                                    styleText={{
                                        marginLeft: 'auto',
                                        paddingRight: 16,
                                    }}
                                    store={organizationTableStore}
                                    style={{ width: 110 }}
                                >
                                    {t('table.itn')}
                                </HCell>
                                <HCell orderBy='createdAt' store={organizationTableStore}
                                       styleText={{whiteSpace: 'nowrap'}}
                                       style={{width: 160}} testid="persons-modifiedAt">Дата создания</HCell>
                                <HCell orderBy='modifiedAt' store={organizationTableStore}
                                       styleText={{whiteSpace: 'nowrap'}}
                                       style={{width: 160}}>Дата обновления</HCell>
                                <HCell orderBy='ticks' store={organizationTableStore} styleText={{whiteSpace: 'nowrap'}}
                                       style={{width: 160}}>Тик</HCell>
                                <HCell store={organizationTableStore} testid="persons-action"
                                       style={{width: 10, padding: '0 8px 0 0'}}></HCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {organizationTableStore.items.length > 0 &&
                                organizationTableStore.items.map(
                                    (organization) => {
                                        return (
                                            <Fragment key={organization.id}>
                                                <TableRow
                                                    hover
                                                    key={organization.id}
                                                >
                                                    <ExpandButton
                                                        item={organization}
                                                        isOpen={organization.expand}
                                                        testid="organizationTable"
                                                        onClick={() => {
                                                            if (organization.expand) {
                                                                organization.notesTable.getCount()
                                                            }
                                                        }}
                                                    />
                                                    <Cell
                                                        style={{
                                                            paddingRight: 10,
                                                            marginRight: 10,
                                                        }}
                                                        testid="organizationTable-id"
                                                    >
                                                        <Typography
                                                            variant="subtitle2"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            {`${organization.id.substring(
                                                                0,
                                                                5
                                                            )}...`}
                                                            <CopyToClipboard
                                                                copyText={
                                                                    organization.id
                                                                }
                                                            />
                                                        </Typography>
                                                    </Cell>
                                                    <Cell
                                                        style={{ width: 70 }}
                                                        testid="organizationTable-externalId"
                                                    >
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="subtitle2"
                                                            style={{
                                                                alignItems:
                                                                    'center',
                                                                display: 'flex',
                                                                width: 100,
                                                            }}
                                                        >
                                                            {
                                                                organization.externalId
                                                            }
                                                            <CopyToClipboard
                                                                copyText={
                                                                    organization.externalId
                                                                }
                                                            />
                                                        </Typography>
                                                    </Cell>
                                                    <Cell
                                                        style={{
                                                            maxWidth: 180,
                                                        }}
                                                        testid="organizationTable-orgName"
                                                    >
                                                        <NavLink
                                                            to={`${organization.id}`}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                sx={{
                                                                    maxWidth: 180,
                                                                    overflow:
                                                                        'hidden',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                }}
                                                            >
                                                                {
                                                                    organization.orgName
                                                                }
                                                            </Typography>
                                                        </NavLink>
                                                    </Cell>
                                                    <Cell
                                                        style={{ width: 100 }}
                                                        testid="organizationTable-orgStatus"
                                                    >
                                                        <SeverityPill
                                                            color={
                                                                organizationStatusHandler(
                                                                    organization
                                                                        .orgStatus
                                                                        .nameRus ||
                                                                        ''
                                                                ) as SeverityPillColor
                                                            }
                                                        >
                                                            <Tooltip
                                                                title={
                                                                    organization
                                                                        .orgStatus
                                                                        .nameRus
                                                                        ? organization
                                                                              .orgStatus
                                                                              .nameRus
                                                                        : 'Работает'
                                                                }
                                                            >
                                                                <Typography
                                                                    variant="caption"
                                                                    align={
                                                                        'left'
                                                                    }
                                                                    sx={{
                                                                        maxWidth: 130,
                                                                        textOverflow:
                                                                            'ellipsis',
                                                                        overflow:
                                                                            'hidden',
                                                                    }}
                                                                >
                                                                    {organization
                                                                        .orgStatus
                                                                        .nameRus
                                                                        ? organization
                                                                              .orgStatus
                                                                              .nameRus
                                                                        : 'Работает'}
                                                                </Typography>
                                                            </Tooltip>
                                                        </SeverityPill>
                                                    </Cell>
                                                    <Cell
                                                        style={{
                                                            maxWidth: 150,
                                                        }}
                                                        testid="organizationTable-typeOrg"
                                                    >
                                                        <Typography
                                                            variant="subtitle2"
                                                            color="textSecondary"
                                                        >
                                                            {
                                                                organization
                                                                    .typeOrg
                                                                    .nameRus
                                                            }
                                                        </Typography>
                                                    </Cell>
                                                    <Cell
                                                        style={{
                                                            maxWidth: 180,
                                                        }}
                                                        testid="organizationTable-address"
                                                    >
                                                        <Typography
                                                            variant="subtitle2"
                                                            color="textSecondary"
                                                        >
                                                            {
                                                                organization
                                                                    .address
                                                                    .fullAddress
                                                            }
                                                        </Typography>
                                                    </Cell>
                                                    <Cell
                                                        style={{
                                                            maxWidth: 110,
                                                        }}
                                                        testid="organizationTable-itn"
                                                        align="right"
                                                    >
                                                        <Typography
                                                            variant="subtitle2"
                                                            color="textSecondary"
                                                        >
                                                            {organization.itn}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell style={{width: 160}}>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {organization.createdAt}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell style={{width: 160}}>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {organization.modifiedAt}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell style={{width: 160}}>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {organization.ticks}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell
                                                        align={'center'}
                                                        testid="organizationTable-action"
                                                    >
                                                        <MenuComponent>
                                                            <MenuItemComponent
                                                                text={t(
                                                                    'table.updateTick'
                                                                )}
                                                                icon={
                                                                    <CloudUploadIcon fontSize="small" />
                                                                }
                                                                onClick={async () =>
                                                                    await organization.propertiesStore.updateTick()
                                                                }
                                                            />
                                                        </MenuComponent>
                                                    </Cell>
                                                </TableRow>
                                                {organization.expand && (
                                                    <ExpandRow
                                                        key={`${organization.id}_`}
                                                        item={organization}
                                                    >
                                                        <OrganizationDetails
                                                            store={organization}
                                                            style={{
                                                                margin: '0 40px',
                                                            }}
                                                        />
                                                    </ExpandRow>
                                                )}
                                            </Fragment>
                                        );
                                    }
                                )}
                        </TableBody>
                    </Table>
                    {!organizationTableStore.items.length && <EmptyBox />}

                    {(organizationTableStore.items.length > 0 ||
                        !!organizationTableStore.count) && (
                        <Pagination store={organizationTableStore} />
                    )}

                    {(!organizationTableStore.items.length ||
                        organizationTableStore.loading) && (
                        <Progress store={organizationTableStore} />
                    )}
                </Card>
            </>
        </AuthorizePermission>
    );
});
