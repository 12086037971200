import {action, makeObservable, observable} from 'mobx';
import {OrganizationNoteDto, OrganizationNotesApi} from '../../../../../services/management';
import {PagedItems, TableStore} from '../../../../utils';
import {getApiConfiguration} from '../../../../../core';
import {DefaultFormModalStore} from '../../../../../components/shared/DefaultFormModalStore';
import {DefaultValueStore} from '../../../../../components/shared/DefaultValueStore';
import {t} from "i18next";
import {NotesTabsStore} from "./NotesTabsStore";

export class NotesTableStore extends TableStore<OrganizationNoteDto> {
    readonly _store: NotesTabsStore;
    current: OrganizationNoteDto | undefined | null;
    deleteDialogState: boolean = false;
    processing: boolean = false;

    readonly creator: DefaultFormModalStore<{
        notes: DefaultValueStore<string>
    }> = new DefaultFormModalStore({
        notes: new DefaultValueStore<string>("")
    },  this.create.bind(this))

    isAdmin: boolean;

    constructor(store: NotesTabsStore, isAdmin: boolean) {
        super();
        this._store = store;
        this.isAdmin = isAdmin;
        this._order = 'descending';
        this.creator.fields.notes.setValidator((e) => {
            return e?.length! > 500 ? t('errors.lengthText').replace("lengthText", "500") : ""
        })
        this.creator.fields.notes.required = true;

        makeObservable(this, {
            current: observable,
            remove: action,
            deleteDialogState: observable,
        });
    }

    async remove() {
        this.processing = true;
        const api: OrganizationNotesApi = new OrganizationNotesApi(getApiConfiguration());
        const result = await api.deleteOrganizationNote({
            id: this?.current?.id ?? '',
        });
        if (result) {
            this.pull();
        }
        this.processing = false;
        this._store.getCount()
    }

    async request(): Promise<PagedItems<OrganizationNoteDto>> {
        let filters: any = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            organizationId: this._store._store.id,
            isAdmin: this.isAdmin
        };
        const api: OrganizationNotesApi = new OrganizationNotesApi(getApiConfiguration());
        const count = await api.getOrganizationNotesCount(filters);
        const newList: OrganizationNoteDto[] = [];
        try {
            const result = await api.getOrganizationNotes(filters);
            for (const item of result) {
                newList.push(item)
            }
        } catch {
        }
        return new PagedItems<OrganizationNoteDto>(newList, count.count)
    };

    async create(): Promise<void> {
        this.processing = true;
        const result = await new OrganizationNotesApi(getApiConfiguration()).createOrganizationNote({
            setOrganizationNoteDto: {
                organizationId: this._store._store.id,
                note: this.creator.model.notes,
            }
        });
        const newUserResult = await new OrganizationNotesApi(getApiConfiguration()).getOrganizationNoteById({id: result.id!});
        this.current = newUserResult
        this.items = [this.current, ...this.items];
        this.processing = false;
        this._store.getCount()
    };
}
