import {action, makeObservable, observable} from 'mobx';
import {getApiConfiguration, getObject, setObject} from '../../../core';
import {
    FormPropertiesApi,
    FormPropertyDto,
    ListSortDirection,
    LocalityDto,
    OrganizationsApi,
    OrganizationStatusDto,
    OrganizationStatusesApi,
    TypeOrgDto,
    TypeOrgsApi
} from '../../../services/management';
import {PagedItems, searchStatusWorkOrgHandler, TableLocalFilters, TableStore} from '../../utils';
import {OrganizationItemStore} from "./OrganizationItemStore";
import {OrganizationCreatorStore} from "./creator/OrganizationCreatorStore";
import {DefaultMultiSelectStore} from "../../../components/shared/DefaultMultiSelectStore";
import {DefaultValueStore} from '../../../components/shared/DefaultValueStore';
import {JurisdictionData} from "../../authorization/AuthorizationStore";
import {LocalityFilterStore} from "../../../components/select/Locality/LocalityFilterStore";
import {ManagementStore} from "../ManagementStore";

interface TableLocalOrgFilters<T> extends TableLocalFilters<T> {
    emptyITN?: boolean;
    hasNoParent?: boolean;
}

export class OrganizationTableStore extends TableStore<OrganizationItemStore> {
    readonly store: ManagementStore;
    readonly api: OrganizationsApi = new OrganizationsApi(getApiConfiguration());
    readonly creator: OrganizationCreatorStore = new OrganizationCreatorStore(this);
    current: OrganizationItemStore | undefined | null;
    typeDepartmentList: string[] = [];

    //FILTERS
    private _statusIds?: string[];
    private _orgTypeIds?: string[];
    private _formPropertyIds?: string[];
    private _localityIds?: string[];
    private _localityHouse?: string;
    private _searchITN?: string;

    readonly formPropertyFilter: DefaultMultiSelectStore<FormPropertyDto>;
    readonly statusFilter: DefaultMultiSelectStore<OrganizationStatusDto>;
    readonly typeFilter: DefaultMultiSelectStore<TypeOrgDto>;
    readonly localitiesFilter: LocalityFilterStore;
    readonly emptyITN: DefaultValueStore<boolean>;
    readonly hasNoParent: DefaultValueStore<boolean>;
    readonly searchByAntidoubleId: DefaultValueStore<string> = new DefaultValueStore<string>("", null, async (e) => await this.updateState());

    constructor(store: ManagementStore) {
        super();
        this.store = store;

        const filters = getObject<TableLocalOrgFilters<OrganizationItemStore>>('organizations-store');
        this._order = ListSortDirection.Descending;
        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);
            this._order = filters.order;
            this._orderBy = filters.orderBy;
            this.searchById.setValueWithoutEffects(filters.searchById);
            this.searchByExternalId.setValueWithoutEffects(filters.searchByExternalId);
        }

        this.formPropertyFilter = new DefaultMultiSelectStore([],
            (filters) => new FormPropertiesApi(getApiConfiguration()).getFormProperties(filters),
            (items) => this.formPropertyIds = items);

        this.statusFilter = new DefaultMultiSelectStore([],
            async (filters) => {
                let statuses = await new OrganizationStatusesApi(getApiConfiguration()).getOrganizationStatuses({
                    ...filters,
                    orderBy: 'name_rus',
                    order: 'ascending'
                })
                return searchStatusWorkOrgHandler(statuses, filters)
            },
            (items) => this.statusIds = items);

        this.typeFilter = new DefaultMultiSelectStore([],
            (filters) => new TypeOrgsApi(getApiConfiguration()).getTypeOrgs(filters),
            (items) => this.orgTypeIds = items);

        this.emptyITN = new DefaultValueStore<boolean>(filters?.emptyITN ?? false, null, async (e) => await this.updateState());
        this.hasNoParent = new DefaultValueStore<boolean>(filters?.hasNoParent ?? false, null, async (e) => await this.updateState());

        this.localitiesFilter = new LocalityFilterStore((items) => this.localitiesIds = items);

        makeObservable(this, {
            current: observable,
            getOrganizationHuck: action,
            getOrganizationPage: action,
            typeDepartmentList: observable
        });
    }

    set localityIds(value: LocalityDto[]) {
        this._localityIds = value.map(x => x.id);
        this.page = 0
        this.pull()
    }

    set formPropertyIds(value: FormPropertyDto[]) {
        this._formPropertyIds = value.map(x => x.id);
        this.page = 0
        this.pull()
    }

    set orgTypeIds(value: TypeOrgDto[]) {
        this._orgTypeIds = value.map(x => x.id);
        this.page = 0
        this.pull()
    }

    set statusIds(value: OrganizationStatusDto[]) {
        this._statusIds = value.map(x => x.id);
        this.page = 0
        this.pull()
    }

    set localitiesIds(value: LocalityDto[]) {
        this._localityIds = value.map(x => x.id);
        this._localityHouse = this.localitiesFilter.house ? this.localitiesFilter.house?.value! : '';
        this.page = 0
        this.pull()
    }

    set searchITN(value: string) {
        this._searchITN = value;
        this.page = 0;
        this.pull()
    }

    get searchITN() {
        return this._searchITN!
    }

    resetFilters() {
        this.search.reset()
        this.search.reset()
        this.searchById.reset();
        this.searchById.reset()
        this.searchByExternalId.reset()
        this.searchByExternalId.reset()
        this.searchByAntidoubleId.reset()
        this.searchITN = '';
        this.emptyITN.value = false;
        this.hasNoParent.value = false;

        this.formPropertyFilter.value = [];
        this._formPropertyIds = [];

        this.statusFilter.value = [];
        this._statusIds = [];

        this.typeFilter.value = [];
        this._orgTypeIds = []

        this._localityIds = [];
        this._localityHouse = '';

        this.localitiesFilter.resetItems();

        setTimeout(() => this.pull(), 1000)
    }

    async request(): Promise<PagedItems<OrganizationItemStore>> {
        const filters = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            itn: this.searchITN ? this.searchITN : undefined,
            emptyItn: !!this.emptyITN.value,
            hasNoParent: !!this.hasNoParent.value,
            statusIds: this._statusIds?.length ? this._statusIds : undefined,
            typeOrgIds: this._orgTypeIds?.length ? this._orgTypeIds : undefined,
            formPropertyIds: this._formPropertyIds?.length ? this._formPropertyIds : undefined,
            localityIds: this._localityIds?.length ? this._localityIds : undefined,
            searchByHouse: this._localityHouse ? !this._localityHouse.includes("/") ? this._localityHouse : this._localityHouse.replace("/", "//") : undefined,
            searchById: this.searchById.value ?? undefined,
            searchByExternalId: this.searchByExternalId.value ?? undefined,
            searchByAntidoubleId: this.searchByAntidoubleId.value ?? undefined,
        };

        const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration());
        let count = 0
        const newList: OrganizationItemStore[] = [];
        try {
            const result = await api.getOrganizations(filters);
            count = !!this.searchById.value || !!this.searchByExternalId.value ? result.length : (await api.getOrganizationsCount(filters)).count;

            for (const item of result) {
                const existsItem = this.items.find(t => t.id === item.id);
                if (existsItem) {

                    existsItem.update(item);
                    newList.push(existsItem);
                    continue;
                }
                newList.push(new OrganizationItemStore(this, item))
            }
        } catch (e) {

        }
        if (!this.typeDepartmentList.length) {
            await this.getTypeDepartmentList();
        }

        return new PagedItems<OrganizationItemStore>(newList, count)
    };

    onFiltersSave() {
        setObject<TableLocalOrgFilters<OrganizationItemStore>>('organizations-store-id', {
            page: this.page,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            searchById: this.searchById.value ?? '',
            searchByExternalId: this.searchByExternalId.value ?? '',
            emptyITN: this.emptyITN.value!,
            hasNoParent: this.hasNoParent.value!,
        });
    }

    async getTypeDepartmentList(): Promise<void> {
        const api: TypeOrgsApi = new TypeOrgsApi(getApiConfiguration());
        try {
            let result = await api.getTypeOrgs({
                parentId: JurisdictionData.organizationTypes.depsId,
                queryType: 'tree',
                size: 100,
                showOnlyStructural: true
            });
            this.typeDepartmentList = result.map(x => x.id)
        } catch (e) {
        }
    }

    async getOrganizationPage(id: string): Promise<OrganizationItemStore | undefined | null> {
        const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration({notFoundRedirect: true}));
        const result = await api.getOrganizationById({ id: id });
        if (result) {
            this.current = new OrganizationItemStore(this, result);
        }
        await this.current?.updateState()
        return this.current;
    };

    async getOrganizationHuck(id: string, newPage?: boolean): Promise<OrganizationItemStore | undefined | null> {
        let organization = null;
        if (!newPage) {
            if (this.current && this.current.id === id) {
                organization = this.current;
            }
            const existItem = this.items.find(t => t.id === id);
            if (existItem) {
                organization = existItem;
            }
        }
        else {
            const api: OrganizationsApi = new OrganizationsApi(getApiConfiguration({notFoundRedirect: true}));
            const result = await api.getOrganizationById({ id: id });
            if (result) {
                organization = new OrganizationItemStore(this, result);
            }
        }
        return organization;
    };
}
