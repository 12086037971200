import {Box, Card, Checkbox, Table, TableBody, TableHead, TableRow, Tooltip, Typography} from "@mui/material";
import {observer} from "mobx-react";
import {Fragment} from 'react';
import {Cell, EmptyBox, ExpertDialog, HCell, Page, PaginationLocal, Progress,} from '../../../../components';
import {useTranslation} from "react-i18next";
import {Scrollbar} from "../../../../scrollbar";
import {SeverityPill, SeverityPillColor} from "../../../../components/severity-pill";
import {personStatusHandler} from "../../Persons";
import {NavLink} from "../../../../components/NavLink";
import {CopyToClipboard} from "../../../../components/CopyToClipboard";
import {JobArchiveTableStore} from "../../../../stores/management/persons/tabs/jobs/jobArchive/JobArchiveTableStore";
import {JobArchiveCreator} from "./JobArchiveCreator";
import {MenuComponent} from "../../../../components/MenuComponent";
import {MenuItemComponent} from "../../../../components/MenuItemComponent";
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';

interface Props {
    store: JobArchiveTableStore;
    double?: boolean;
    style?: React.CSSProperties;
}

export const JobArchiveTable = observer(({ store, style }: Props) => {
    const { t } = useTranslation();

    return (
        <Page style={style}>
            {!!store.creator && <ExpertDialog
                title={t('common.unarchive')}
                open={store?.creator.creator.Open}
                onClose={() => store.creator.Open(false)}
            >
                <JobArchiveCreator store={store.creator}/>
            </ExpertDialog>}
            <Card sx={{ mt: 1 }}>
                <Scrollbar>
                    <Table sx={{ minWidth: 1050 }}>
                        <TableHead>
                            <TableRow>
                                <HCell width='2%' testid="jobArchive-expand"></HCell>
                                <HCell orderBy='id' store={store} style={{width: 60}} testid="jobArchive-Id">Id</HCell>
                                <HCell orderBy='organizationName' store={store} width='15%' testid="jobArchive-organizationName">{t('table.organization')}</HCell>
                                <HCell orderBy='orgType' store={store} width='12%' testid="jobArchive-orgType">{t('table.type')}</HCell>
                                <HCell orderBy='post' width='12%' store={store} testid="jobArchive-post">{t('table.post')}</HCell>
                                <HCell width='11%' store={store}>Телемедицина</HCell>
                                <HCell orderBy='status' width='10%' store={store} testid="jobArchive-status">{t('table.status')}</HCell>
                                <HCell orderBy='address' width='15%' store={store} testid="jobArchive-address">{t('table.address')}</HCell>
                                {
                                    !store._store.isDouble &&
                                    <HCell store={store} width='7%' style={{padding: '0 15px'}}
                                           testid="jobArchive-action">{t('table.action')}</HCell>
                                }
                                <HCell orderBy='createdAt' store={store} styleText={{whiteSpace: 'nowrap'}}
                                       style={{width: 160}} testid="persons-modifiedAt">Дата создания</HCell>
                                <HCell orderBy='modifiedAt' store={store} styleText={{whiteSpace: 'nowrap'}}
                                       style={{width: 160}}>Дата обновления</HCell>
                                <HCell orderBy='ticks' store={store} styleText={{whiteSpace: 'nowrap'}}
                                       style={{width: 160}}>Тик</HCell>
                                <HCell store={store} style={{width: 10, padding: '0 8px 0 0'}}></HCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                store.items.length > 0
                                    ? store.items.map(item => {
                                        // const open = item.id === openDetails;
                                        return (
                                            <Fragment key={item.id}>
                                                <TableRow
                                                    hover
                                                    key={item.id}
                                                    onClick={() => store.current = item}
                                                >
                                                    <Cell testid="jobArchive-expand" style={{ height: 60 }} />
                                                    <Cell testid="jobArchive-id">
                                                        <Typography variant="subtitle2"
                                                            style={{ display: "flex", alignItems: 'center' }}>
                                                            {`${item.dto.organization.id.substring(0, 5)}...`}
                                                            <CopyToClipboard copyText={item.dto.organization.id}/>
                                                        </Typography>
                                                    </Cell>
                                                    <Cell style={{ maxWidth: 150 }} titleText={item.organizationName} testid="jobArchive-organizationName">
                                                        <NavLink to={`/organizations/${item.dto.organization.id}`}
                                                                 target="_blank">
                                                            <Tooltip title={item.organizationName}>
                                                                <Typography variant="subtitle2"
                                                                    style={{
                                                                        maxWidth: 150,
                                                                        display: '-webkit-box',
                                                                        WebkitLineClamp: 2,
                                                                        WebkitBoxOrient: 'vertical',
                                                                        overflow: 'hidden'
                                                                    }}
                                                                >
                                                                    {item.organizationName}
                                                                </Typography>
                                                            </Tooltip>
                                                        </NavLink>
                                                    </Cell>
                                                    <Cell testid="jobArchive-orgType">
                                                        {item.orgType}
                                                    </Cell>
                                                    <Cell titleText={item.post} testid="jobArchive-post">
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body2">
                                                            {item.post}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell style={{minWidth: 130}}>
                                                        <Checkbox
                                                            checked={!!item.dto.telemedicine}
                                                            disabled={true}
                                                        />
                                                    </Cell>
                                                    <Cell testid="jobArchive-status">
                                                        {("status" in item.dto) &&
                                                            <SeverityPill
                                                                color={personStatusHandler(item.dto.status?.nameRus || '') as SeverityPillColor}>
                                                                <Tooltip
                                                                    title={item.dto.status?.nameRus ? item.dto.status?.nameRus : 'Работает'}>
                                                                    <Typography variant="caption" align={'left'} sx={{
                                                                        maxWidth: 130,
                                                                        textOverflow: 'ellipsis',
                                                                        overflow: 'hidden',
                                                                    }}>
                                                                        {item.dto.status?.nameRus}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </SeverityPill>
                                                        }
                                                    </Cell>
                                                    <Cell titleText={item.address} testid="jobArchive-address">
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body2">
                                                            {item.address}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell style={{width: 160}}>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {item.createdAt}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell style={{width: 160}}>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {item.modifiedAt}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell style={{width: 160}}>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {item.ticks}
                                                        </Typography>
                                                    </Cell>
                                                    {
                                                        !store._store.isDouble &&
                                                        <Cell align={'center'} testid="jobArchive-action">
                                                            <MenuComponent>
                                                                {!!store.creator &&
                                                                    <MenuItemComponent text={t('common.unarchive')}
                                                                                       icon={<UnarchiveOutlinedIcon
                                                                                           fontSize="small"/>}
                                                                                       onClick={() => store.creator.Open(true)}
                                                                                       disabled={store._store.isDouble || store._store.isDead}/>
                                                                }
                                                            </MenuComponent>
                                                        </Cell>
                                                    }
                                                </TableRow>
                                            </Fragment>)
                                    })
                                    : <Cell colSpan={9}>
                                        <EmptyBox />
                                    </Cell>
                            }
                        </TableBody>
                    </Table>
                </Scrollbar>
            </Card>
            {store.items.length > 0 && <PaginationLocal store={store} />}
            {!store.items.length && !!store.loading && <Box
                sx={{
                    flexGrow: 1,
                    py: 3,
                }}
            >
                <Progress store={store} />
            </Box>
            }
        </Page>
    );
});
