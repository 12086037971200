import {Box, Breadcrumbs, Card, Typography} from "@mui/material";
import {observer} from 'mobx-react';
import {useSnackbar} from "notistack";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {AuthorizePermission, PageTab, PageTabs} from '../../components';
import {AllPermissions} from '../../core/const';
import {FolderOpenOutline as FolderOpenOutlineIcon} from '../../icons/folder-open-outline';
import {Layers as LayersIcon} from '../../icons/layers';
import {List as OrgListIcon} from '../../icons/list';
import {OrgAddress as OrgAddressIcon} from '../../icons/orgAddress';
import {OrgEmployees as OrgEmployeesIcon} from '../../icons/orgEmployes';
import {OrgNames as OrgNamesIcon} from "../../icons/orgNames";
import {OrgParent as OrgParentIcon} from '../../icons/orgParent';
import {PersonParams as PersonParamsIcon} from "../../icons/personParams";
import {useManagementStore} from "../../stores";
import {OrganizationAddress} from "./details/OrganizationAddress";
import {OrganizationProperties} from "./details/OrganizationProperties";
import {OrganizationEmployeesTable} from './details/employees/OrganizationEmployeesTable';
import {OrganizationNameTable} from "./details/names/OrganizationNameTable";
import {OthersTab} from './details/others/OthersTab';
import {ParentChildTabs} from './details/parentChild/ParentChildTabs';
import {DoubleTable} from './doubles/DoubleTable';
import {NotesTabs} from "./details/notes/NotesTabs";

export const Organization = observer(() => {
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams<{ id: string }>();
    const { organizationTableStore } = useManagementStore();
    const organization = organizationTableStore.current;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleOrganizationsNavigate = () => {
        navigate('/organizations');
    }

    useEffect(() => {
        get();
    }, [id, organizationTableStore.store.store.authorizationStore.jurisdictions.value?.jurisdictionId]);

    const get = async () => {
        try {
            if (id) {
               await organizationTableStore.getOrganizationPage(id);
            }
        } catch (e) {
        }
    };

    const renderTabs = (index: number) => {
        if (organization) {
            switch (index) {
                case 0:
                    return (
                        <AuthorizePermission permissions={AllPermissions.organization.read} showWarning>
                            <OrganizationProperties store={organization.propertiesStore} style={{ flex: 1 }} />
                        </AuthorizePermission>
                    )
                case 1:
                    return (
                        <DoubleTable store={organization.doubleStore} style={{ flex: 1 }} />
                    )
                case 2:
                    return (
                        <AuthorizePermission permissions={AllPermissions.organization.names.read} showWarning>
                            <OrganizationNameTable store={organization.orgNamesStore} style={{ flex: 1 }} />
                        </AuthorizePermission>
                    )
                case 3:
                    return (
                        <AuthorizePermission permissions={AllPermissions.organization.addressunique.read} showWarning>
                            <OrganizationAddress store={organization.addressesStore} />
                        </AuthorizePermission>
                    )
                case 4:
                    return (
                        <AuthorizePermission permissions={AllPermissions.organization.parent.read} showWarning>
                            <ParentChildTabs store={organization.parentChildStore} />
                        </AuthorizePermission>
                    )
                case 5:
                    return <OthersTab store={organization.othersStore} />;
                case 6:
                    return (
                        <AuthorizePermission permissions={AllPermissions.person.read} showWarning>
                            <OrganizationEmployeesTable store={organization.employeesStore} /></AuthorizePermission>
                    )
                case 7:
                    return (
                        <NotesTabs store={organization.notesTable}/>
                    )
                default:
                    return null;
            }
        }
    };
    return (
        <>
            <AuthorizePermission permissions={AllPermissions.organization.read} showWarning>
                <Box>
                    {
                        organization
                            ? (
                                <Breadcrumbs
                                    separator="/"
                                    sx={{ mt: 1 }}
                                >
                                    <Box onClick={handleOrganizationsNavigate} style={{ cursor: 'pointer' }}>
                                        <Typography color="primary"
                                            variant="h6">{t('pageNames.organizations')}
                                        </Typography>
                                    </Box>
                                    <Typography color="textSecondary"
                                        variant="h6">
                                        {organization.orgName}
                                    </Typography>
                                </Breadcrumbs>
                            )
                            : '...'
                    }
                </Box>

                <Card
                    style={{ display: 'flex', flexDirection: 'column' }}
                    sx={{
                        px: 2,
                        mt: 2
                    }}
                >
                    {organization
                        ? (
                            <>
                                <PageTabs
                                    index={organization.tabIndex}
                                    onChange={t => organization.tabIndex = t}>
                                    <PageTab icon={<PersonParamsIcon />} title={t('common.details')}
                                        style={{ width: '12%' }} testid="organization-details-tabs" />
                                    <PageTab icon={<LayersIcon />} title={t('common.doubles')} testid="organization-doubles-tabs"
                                        style={(organization._dto?.status?.nameRus.toLowerCase() === 'дубль') ? { display: 'none' } : { width: '12%' }} />
                                    <PageTab icon={<OrgNamesIcon />} title={t('common.orgNames')}
                                        style={{ width: '12%' }} testid="organization-boorgNamesunds-tabs" />
                                    <PageTab icon={<OrgAddressIcon />} title={t('common.orgAddress')}
                                        style={{ width: '12%' }} testid="organization-orgAddress-tabs" />
                                    <PageTab icon={<OrgParentIcon />} title={t('common.orgParentChild')}
                                        style={{ width: '12%' }} testid="organization-orgParent-tabs" />
                                    <PageTab icon={<FolderOpenOutlineIcon />} title={t('common.other')}
                                        style={{ width: '12%' }} testid="organization-orgOthers-tabs" />
                                    <PageTab icon={<OrgEmployeesIcon />} title={t('common.orgEmployees')}
                                        style={{ width: '12%' }} testid="organization-orgEmployees-tabs" />
                                    <PageTab icon={!organization.countNotes ? <OrgListIcon /> : <><OrgListIcon />{organization.countNotes}</>} title={t('common.notes')}
                                        style={{ width: '12%' }} testid="organization-orgNotes-tabs" />
                                </PageTabs>
                                <Box sx={{ mt: 2, mb: 2 }}>
                                    {renderTabs(organization.tabIndex)}
                                </Box>
                            </>)
                        : '...'
                    }
                </Card>
            </AuthorizePermission>
        </>
    );
});
