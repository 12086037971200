import {action, makeObservable, observable} from 'mobx';
import {ManagementStore} from '..';
import {getApiConfiguration, getObject, setObject} from '../../../core';
import {
    GenderDto,
    GendersApi,
    GetPersonsRequest,
    JobStatusesApi,
    ListSortDirection,
    PersonsApi,
    PersonStatusDto,
    PersonStatusesApi,
    PersonTagsApi,
    PostDto,
    PostsApi,
    SpecialitiesApi,
    SpecialityDto,
    TagDto
} from '../../../services/management';
import {PagedItems, TableLocalFilters, TableStore} from '../../utils';
import {LocalityFilterStore} from "../../../components/select/Locality/LocalityFilterStore";
import {DefaultMultiSelectStore} from '../../../components/shared/DefaultMultiSelectStore';
import {PersonItemStore} from "./PersonItemStore";
import {DefaultValueStore} from "../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from '../../../components/shared/DefaultSelectStore';
import {PersonCreatorStore} from "./PersonCreatorStore";

export class PersonTableStore extends TableStore<PersonItemStore> {
    readonly store: ManagementStore;
    readonly api: PersonsApi = new PersonsApi(getApiConfiguration());
    readonly creator: PersonCreatorStore = new PersonCreatorStore(this);
    current: PersonItemStore | undefined | null;

    //FILTERS

    readonly nameFilter: DefaultValueStore<string> = new DefaultValueStore<string>("", null, async (e) => await this.updateState());
    readonly surnameFilter: DefaultValueStore<string> = new DefaultValueStore<string>("", null, async (e) => await this.updateState());
    readonly middleNameFilter: DefaultValueStore<string> = new DefaultValueStore<string>("", null, async (e) => await this.updateState());
    readonly midenNameFilter: DefaultValueStore<string> = new DefaultValueStore<string>("", null, async (e) => await this.updateState());
    readonly statusesFilter: DefaultMultiSelectStore<PersonStatusDto>;
    readonly postsFilter: DefaultMultiSelectStore<PostDto>;
    readonly specialityFilter: DefaultMultiSelectStore<SpecialityDto>;
    readonly genderFilter: DefaultSelectStore<GenderDto>;
    readonly statusJobsFilter: DefaultSelectStore<PersonStatusDto>;
    readonly localitiesFilter: LocalityFilterStore;
    readonly tagsFilter: DefaultMultiSelectStore<TagDto>;
    readonly searchByAntidoubleId: DefaultValueStore<string> = new DefaultValueStore<string>("", null, async (e) => await this.updateState());

    constructor(store: ManagementStore) {
        super();
        this.store = store;

        this.statusesFilter = new DefaultMultiSelectStore([],
            (filters) => new PersonStatusesApi(getApiConfiguration()).getPersonStatuses({ ...filters }),
            async (e) => await this.updateState());

        this.postsFilter = new DefaultMultiSelectStore([],
            (filters) => new PostsApi(getApiConfiguration()).getPosts({ ...filters, orderBy: 'name_rus', order: 'ascending' }),
            async (e) => await this.updateState());

        this.specialityFilter = new DefaultMultiSelectStore([],
            (filters) => new SpecialitiesApi(getApiConfiguration()).getSpecialities(filters),
            async (e) => await this.updateState());

        this.genderFilter = new DefaultSelectStore<GenderDto>(null,
            (filters) => new GendersApi(getApiConfiguration()).getGenders(filters))
        this.genderFilter.onChanged = [async (e) => await this.updateState()]

        this.statusJobsFilter = new DefaultSelectStore<PersonStatusDto>(null,
            (filters) => new JobStatusesApi(getApiConfiguration()).getJobStatuses(filters))
        this.statusJobsFilter.onChanged = [async (e) => await this.updateState()]

        this.tagsFilter = new DefaultMultiSelectStore([],
            (filters) => new PersonTagsApi(getApiConfiguration()).getPersonTags({ ...filters }),
            async (e) => await this.updateState());

        this.localitiesFilter = new LocalityFilterStore(async (e) => await this.updateState());
        this._order = ListSortDirection.Descending;
        const filters = getObject<TableLocalFilters<PersonItemStore>>('persons-store');
        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);;
            this._order = filters.order;
            this._orderBy = filters.orderBy;
            this.searchById.setValueWithoutEffects(filters.searchById);
            this.searchByExternalId.setValueWithoutEffects(filters.searchByExternalId);
        }

        makeObservable(this, {
            current: observable,
            getPersonPage: action,
            getPersonHuck: action,
        });
    }

    get localities(): string[] {
        return this.localitiesFilter.items.map(x => x.id)
    }

     //TODO Потом удалить
    async getPersonHuck(id: string, newPage?: boolean): Promise<PersonItemStore | undefined | null> {
        let person = null;
        if (!newPage) {
            if (this.current && this.current.id === id) {
                person = this.current;
            }
            const existItem = this.items.find(t => t.id === id);
            if (existItem) {
                person = existItem;
            }
        }
        else {
            const api: PersonsApi = new PersonsApi(getApiConfiguration({notFoundRedirect: true}));
            const result = await api.getPersonById({ id: id });
            if (result) {
                person  = new PersonItemStore(this, result);
            }
        }
        return person;
    };

    async getPersonPage(id: string): Promise<PersonItemStore | undefined | null> {
        const api: PersonsApi = new PersonsApi(getApiConfiguration({notFoundRedirect: true}));
        const result = await api.getPersonById({ id: id });
        if (result) {
            this.current = new PersonItemStore(this, result);
        }
        await this.current?.updateState()
        return this.current;
    };

    async resetFilters() {
        this.statusesFilter.reset()
        this.postsFilter.reset()
        this.specialityFilter.reset()
        this.genderFilter.reset()
        this.statusJobsFilter.reset()
        this.localitiesFilter.resetItems();
        this.surnameFilter.reset()
        this.middleNameFilter.reset()
        this.nameFilter.reset()
        this.tagsFilter.reset()

        this.searchById.reset();
        this.searchByExternalId.reset()
        this.searchByExternalId.reset()
        this.searchByAntidoubleId.reset()
        this.search.reset()
        this.search.reset()
        //не успевают проставиться сеттеры
        setTimeout(() => this.pull(), 1000)
    }
    async request(): Promise<PagedItems<PersonItemStore>> {
        const house = this.localitiesFilter.house?.value;
        const filters = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            searchByName: this.nameFilter.value,
            searchBySurname: this.surnameFilter.value,
            searchBySecondname: this.middleNameFilter.value,
            order: this.order,
            orderBy: this.orderBy,
            statuses: this.statusesFilter.value?.length ? this.statusesFilter.value.map(x => x.id) : undefined,
            posts: this.postsFilter.value?.length ? this.postsFilter.value.map(x => x.id) : undefined,
            specialities: this.specialityFilter.value?.length ? this.specialityFilter.value.map(x => x.id) : undefined,
            genderId: this.genderFilter?.value?.id ?? undefined,
            jobStatusId: this.statusJobsFilter?.value?.id ?? undefined,
            searchByHouse: house ? !house.includes("/") ? house : house.replace("/", "//") : undefined,
            searchById: !!this.searchById?.value ? this.searchById.value : undefined,
            searchByExternalId: !!this.searchByExternalId.value ? this.searchByExternalId.value : undefined,
            searchByAntidoubleId: !!this.searchByAntidoubleId.value ? this.searchByAntidoubleId.value : undefined,
            localities: this.localities?.length ? this.localities : undefined,
            tags: this.tagsFilter.value?.length ? this.tagsFilter.value.map(x => x.id) : undefined,
            searchByMaidenname: this.midenNameFilter.value,
        } as GetPersonsRequest;

        const api: PersonsApi = new PersonsApi(getApiConfiguration());
        try {
            const result = await api.getPersons(filters);
            const count = !!this.searchById.value || !!this.searchByExternalId.value ? result.length : (await api.getPersonsCount(filters)).count;

            const newList: PersonItemStore[] = [];
            for (const item of result) {
                const existsItem = this.items.find(t => t.id === item.id);
                if (existsItem) {
                    existsItem.update(item);
                    newList.push(existsItem);
                    continue;
                }
                newList.push(new PersonItemStore(this, item))
            }

            return new PagedItems<PersonItemStore>(newList, count)
        } finally {

        }
    };

    onFiltersSave() {
        setObject<TableLocalFilters<PersonItemStore>>('persons-store-id', {
            search: this.search.value ?? '',
            // searchByName: this._name,
            // searchBySurname: this._surname,
            // searchBySecondname: this._secondname,
            order: this.order,
            orderBy: this.orderBy,
            page: this.page,
            size: this.size,
            searchById: this.searchById.value ?? '',
            searchByExternalId: this.searchByExternalId.value ?? '',
        });
    }
}
