import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IHasTabs} from "../../../../../core";
import {NotesTableStore} from "./NotesTableStore";
import {OrganizationItemStore} from "../../OrganizationItemStore";
import {OrganizationNotesApi} from "../../../../../services/management";

export class NotesTabsStore implements IHasTabs {
    readonly _store: OrganizationItemStore;
    readonly adminNotes: NotesTableStore;
    readonly userNotes: NotesTableStore;
    count: number = 0;
    private _tabIndex: number = 0;

    constructor(store: OrganizationItemStore) {
        this._store = store;
        this.adminNotes = new NotesTableStore(this, true);
        this.userNotes = new NotesTableStore(this, false);

        makeAutoObservable(this);
    }


    async updateState() {
        switch (this.tabIndex) {
            case 0: {
                await this.userNotes.updateState()
                break
            }
            case 1: {
                await this.adminNotes.updateState()
                break
            }
        }
    }

    get tabIndex() {
        return this._tabIndex;
    }

    set tabIndex(index: number) {
        this._tabIndex = index
        this.updateState()
    }

    async getCount() {
        const api: OrganizationNotesApi = new OrganizationNotesApi(getApiConfiguration());
        const count = await api.getOrganizationNotesCount({organizationId: this._store.id});
        this.count = count.count;
    }
}
