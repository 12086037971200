import {computed, makeObservable, observable} from 'mobx';
import {PagedItems, TableLocalFilters, TableStore} from '../../../../utils';
import {IExpanded, setObject} from "../../../../../core";
import {BoundDto} from "../../../../../services/management";
import {MappingsStore} from "./MappingsStore";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import {MappingItemStore} from "./MappingItemStore";
import {DefaultFormModalStore} from "../../../../../components/shared/DefaultFormModalStore";
import {InputMaybe, RecordTypeMappingFilterInput, SortEnumType} from "../../../../../gql/graphql";


export abstract class MappingTableStore<T extends {
    clientValue: string,
    entityId: string | number
}> extends TableStore<MappingItemStore<T>> implements IExpanded {
    readonly store: MappingsStore;
    readonly _dto: BoundDto;
    current: MappingItemStore<T> | undefined | null;
    _expand: boolean = false;
    link?: string;
    searchByClientValue: DefaultValueStore<string> = new DefaultValueStore<string>("", null, (e) => this.updateState());

    filterName: string;

    abstract creator: DefaultFormModalStore<{
        value: DefaultValueStore<string>,
        entity: DefaultSelectStore<any>
    }>;

    abstract getMappings(filters: any): Promise<{ items: ({ item: T, name: string })[], count: number }>;

    abstract removeMapping(filter: {
        boundId: string,
        setMappingDto: Array<{ clientValue: string, entityId: string | number }>
    }): Promise<void>

    abstract setMapping(filter: {
        boundId: string,
        setMappingDto: Array<{ clientValue: string, entityId: string | number }>
    }): Promise<({ item: T, name: string })[]>

    constructor(store: MappingsStore, dto: BoundDto, filterName: string) {
        super();
        this.store = store;
        this._dto = dto;
        this.filterName = filterName;
        makeObservable(this, {
            current: observable,
            expand: computed,
            _expand: observable
        });
        if (this.expand)
            this.loadData()
    }

    get expand(): boolean {
        return this._expand;
    }

    set expand(value: boolean) {
        this._expand = value;
        this.onFiltersSave()
        if (this.expand)
            this.loadData()
    }

    get equals() {
        return !this.items.filter(x => !x.equals)[0]
    }

    get valid(): boolean {
        return this.items.filter(x => x.valid).length == this.items.length
    }

    reset() {
        this.items.forEach(x => x.reset())
    }

    async loadData(): Promise<void> {
        this.loading = true;
        await this.pull();
        this.loading = false;
    }

    async afterLoad(): Promise<void> {
        this.reset()
    }

    reload() {
        if (this.expand)
            this.loadData()
    }

    async request(): Promise<PagedItems<MappingItemStore<T>>> {
        const ff: InputMaybe<Array<RecordTypeMappingFilterInput>> = [
            {
                clientId: {eq: this.store._store.dto.id}
            }
        ];
        if (this.search.value) {
            ff.push({
                entity: {
                    nameRus: {
                        contains: this.search.value
                    }
                },
            })
        }

        ff.push({
            jurisdictionId: {eq: this.store._store._store._store.store.store.authorizationStore.jurisdictions.value?.jurisdictionId!}
        })

        if (this.searchById.value) {
            ff.push({
                entityId: {eq: this.searchById.value},
            })
        }

        if (this.searchByClientValue.value) {
            ff.push({
                clietnValue: {eq: this.searchByClientValue.value},
            })
        }

        const filters = {
            skip: this.page * this.size,
            take: this.size,
            search: this.search.value ?? '',
            order: [
                {entity: {nameRus: SortEnumType.Asc}}
            ],
            where: {
                and: ff
            }
        };
        const {count, items} = await this.getMappings(filters);
        return new PagedItems<MappingItemStore<T>>(items.map(x => new MappingItemStore(x.item, x.name)), count)
    };

    onFiltersSave() {
        setObject<TableLocalFilters<MappingItemStore<T>> & IExpanded>(this.filterName + '-' + this._dto.id, {
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            page: this.page,
            size: this.size,
            expand: this.expand
        });
    }

    async create(): Promise<void> {
        this.processing = true;
        try {
            const result = (await this.setMapping({
                boundId: this._dto.id,
                setMappingDto: [
                    {
                        clientValue: this.creator.fields.value.value!,
                        entityId: this.creator.fields.entity.value?.id!
                    }
                ]
            })).map(x => new MappingItemStore(x.item, x.name))

            this.items = [...this.items, ...result]
            this.request()
        } catch (ex) {
            this.processing = false;
        }
    }

    async delete(): Promise<void> {
        this.processing = true;
        try {
            await this.removeMapping({
                boundId: this._dto.id,
                setMappingDto: [
                    {
                        clientValue: this.store.id,
                        entityId: this.current!.id
                    }
                ]
            });
            this.current!.deleteDialogState = false;
        } catch (e) {

        }
        this.current = null;
        this.processing = false;
        await this.pull()
    };

    async save(): Promise<void> {
        this.processing = true;
        try {
            await this.setMapping({
                boundId: this._dto.id,
                setMappingDto: this.items.filter(x => !x.equals).map(x => ({
                    entityId: x.id,
                    clientValue: x.value!
                }))
            });
        } catch (e) {

        }
        this.current = null;
        this.processing = false;
        await this.pull()
    }
}
