import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, Page, PageTab, PageTabs} from "../../components";
import {AllPermissions} from "../../core/const";
import {FolderOpenOutline as FolderOpenOutlineIcon} from '../../icons/folder-open-outline';
import {Layers as LayersIcon} from '../../icons/layers';
import {List as PersonListIcon} from '../../icons/list';
import {PersonJob as PersonJobIcon} from '../../icons/personJob';
import {PersonParams as PersonParamsIcon} from '../../icons/personParams';
import {PersonSpeciality as PersonSpecialityIcon} from '../../icons/personSpeciality';
import {Parameters} from "./Parameters";
import {DoubleTable} from "./doubles/DoubleTable";
import {JobsTabs} from "./jobs/JobsTabs";
import {OthersTab} from "./others/OthersTab";
import {PersonSpecialities} from "./specialities/PersonSpecialities";
import {PersonItemStore} from "../../stores/management/persons/PersonItemStore";
import {NotesTabs} from "./notes/NotesTabs";

interface Props {
    person: PersonItemStore;
    style?: React.CSSProperties;
}

export const Details = observer(({person, style}: Props) => {
    const {t} = useTranslation();

    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return (
                    <AuthorizePermission permissions={AllPermissions.person.read} showWarning>
                        <Parameters person={person} />
                    </AuthorizePermission>
                )
            case 1:
                return (
                    <DoubleTable store={person.doubleStore} style={{ flex: 1 }} />
                )
            case 2:
                return (
                    <AuthorizePermission permissions={AllPermissions.person.personspecialities.read} showWarning>
                        <PersonSpecialities store={person.specialities}/>
                    </AuthorizePermission>
                )
            case 3:
                return (
                    <AuthorizePermission permissions={AllPermissions.person.personjobs.read} showWarning>
                        <JobsTabs store={person.jobTabs} style={{ flex: 1 }} />
                    </AuthorizePermission>
                )
            case 4:
                return (
                    <AuthorizePermission permissions={AllPermissions.person.notes.read} showWarning>
                        <NotesTabs store={person.notes} style={{flex: 1}}/>
                    </AuthorizePermission>
                )
            case 5:
                return <OthersTab store={person.personOthersStore} style={{flex: 1}}/>;
            default:
                return null;
        }
    };

    return (
        <Page
            style={style}
        >
            <PageTabs
                index={person.tabIndex}
                onChange={t => person.tabIndex = t}>
                <PageTab icon={<PersonParamsIcon />} title={t('personPage.personTabs.properties')}
                         style={{ width: '16%' }} testid="personsDetails-properties-tabs" />
                <PageTab icon={<LayersIcon />} title={t('common.doubles')} testid="personsDetails-doubles-tabs"
                         style={(person.isDouble) ? { display: 'none' } : { width: '16%' }} />
                <PageTab icon={<PersonSpecialityIcon />} title={t('personPage.personTabs.specialities')}
                         style={{ width: '16%' }} testid="personsDetails-specialities-tabs" />
                <PageTab icon={<PersonJobIcon />} title={t('personPage.personTabs.jobs')}
                         style={{ width: '16%' }} testid="personsDetails-jobs-tabs" />
                <PageTab icon={!person.countNotes ? <PersonListIcon /> : <><PersonListIcon />{person.countNotes}</>} title={t('personPage.personTabs.notes')}
                         style={{ width: '16%' }} testid="personsDetails-notes-tabs" />
                <PageTab icon={<FolderOpenOutlineIcon />} title={t('common.other')}
                         style={{ width: '16%' }} testid="personsDetails-orgOthers-tabs" />
            </PageTabs>
            <div style={{ marginTop: 4, marginBottom: 4, padding: 15 }}>
                {renderTabs(person.tabIndex)}
            </div>
        </Page>
    );
});
