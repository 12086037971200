import {action, makeObservable, observable} from 'mobx';
import {PagedItems, TableStore} from "../../../../utils";
import {getApiConfiguration} from "../../../../../core";
import {PersonNoteDto} from '../../../../../services/management/models/PersonNoteDto';
import {PersonNotesApi} from '../../../../../services/management/apis/PersonNotesApi';
import {DefaultFormModalStore} from '../../../../../components/shared/DefaultFormModalStore';
import {DefaultValueStore} from '../../../../../components/shared/DefaultValueStore';
import {t} from "i18next";
import {NotesTabsStore} from "./NotesTabsStore";

export class NotesTableStore extends TableStore<PersonNoteDto> {
    readonly _store: NotesTabsStore;
    current: PersonNoteDto | undefined | null;
    deleteDialogState: boolean = false;
    processing: boolean = false;

    readonly creator: DefaultFormModalStore<{
        notes: DefaultValueStore<string>
    }> = new DefaultFormModalStore({
        notes: new DefaultValueStore<string>("")
    },  this.create.bind(this))

    isAdmin: boolean;

    constructor(store: NotesTabsStore, isAdmin: boolean) {
        super();
        this._store = store;
        this.isAdmin = isAdmin;
        this._order = 'descending';
        this.creator.fields.notes.setValidator((e) => {
            return e?.length! > 500 ? t('errors.lengthText').replace("lengthText", "500") : ""
        })
        this.creator.fields.notes.required = true;

        makeObservable(this, {
            current: observable,
            remove: action,
            deleteDialogState: observable,
        });
    }

    async remove() {
        this.processing = true;
        const api: PersonNotesApi = new PersonNotesApi(getApiConfiguration());
        const result = await api.deletePersonNote({
            id: this?.current?.id ?? '',
        });
        if (result) {
            this.pull();
            this._store.updateState()
        }
        this.processing = false;
        this._store.getCount()
    }

    async request(): Promise<PagedItems<PersonNoteDto>> {
        let filters: any = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            personId: this._store._store.id,
            isAdmin: this.isAdmin
        };
        const api: PersonNotesApi = new PersonNotesApi(getApiConfiguration());
        const count = await api.getPersonNotesCount(filters);
        const newList: PersonNoteDto[] = [];
        try {
            const result = await api.getPersonNotes(filters);
            for (const item of result) {
                newList.push(item)
            }
        } catch {
        }
        return new PagedItems<PersonNoteDto>(newList, count.count)
    };

    async create(): Promise<void> {
        this.processing = true;
        const result = await new PersonNotesApi(getApiConfiguration()).createPersonNote({
            setPersonNoteDto: {
                personId: this._store._store.id,
                note: this.creator.model.notes,
            }
        });
        const newUserResult = await new PersonNotesApi(getApiConfiguration()).getPersonNoteById({id: result.id!});
        this.current = newUserResult
        this.items = [this.current, ...this.items];
        this.processing = false;
        this._store.getCount()
    };
}
