import {makeAutoObservable} from 'mobx';
import {PersonNotesApi} from '../../../../../services/management';
import {PersonItemStore} from "../../PersonItemStore";
import {getApiConfiguration, IHasTabs} from "../../../../../core";
import {NotesTableStore} from "./NotesTableStore";

export class NotesTabsStore implements IHasTabs {
    readonly _store: PersonItemStore;
    readonly adminNotes: NotesTableStore;
    readonly userNotes: NotesTableStore;
    count: number = 0;
    private _tabIndex: number = 0;

    constructor(store: PersonItemStore) {
        this._store = store;
        this.adminNotes = new NotesTableStore(this, true);
        this.userNotes = new NotesTableStore(this, false);

        makeAutoObservable(this);
    }


    async updateState() {
        switch (this.tabIndex) {
            case 0: {
                await this.userNotes.updateState()
                break
            }
            case 1: {
                await this.adminNotes.updateState()
                break
            }
        }
    }

    get tabIndex() {
        return this._tabIndex;
    }

    set tabIndex(index: number) {
        this._tabIndex = index
        this.updateState()
    }

    async getCount() {
        const api: PersonNotesApi = new PersonNotesApi(getApiConfiguration());
        const count = await api.getPersonNotesCount({personId: this._store.id});
        this.count = count.count;
    }
}
